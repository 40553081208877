// Warning! Order is important
import "./styles/variables.css"
import "./styles/reset.css"
import "./styles/globals.css"
import "./styles/common.css"
import "./styles/TEMP.css"

import {Route, Routes, useLocation} from "react-router-dom";
import ScrollToTop from "./components/sharedComponents/ScrollToTop";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {oldUserIdPropertyEvent} from "./utils/dataLayerEvents";
import {parseJsonSafely} from "./utils/GlobalJavascriptFunction";
import {unbxdAnalytics} from "./redux/actions/unbxdActions";
import {SELECTED_ENV, serverUrl} from "@globalConfig";
import useSentry from "./common/hooks/useSentry";
import {page404 as Page404} from "./routes/asyncPages.tsx";
import {setAccessToken, setUserId, setUserToLocalStorage} from "./utils/auth";
import {useFirstRenderHistoryLogic} from "@hooks/useFirstRender";
import useHashToLocalStoreEvent from "@hooks/useHashToLocalStoreEvent";
import {useSetReferral} from "@hooks/useGetReferral";
import toast, {Toaster} from 'react-hot-toast';
import ConfirmPopup from "@componentsShared/ConfirmPopup/ConfirmPopup";
import {usePostHog} from "posthog-js/react";
import cookieClient from "react-cookies";


if ((__IS_CSR__ && __IS_PROD_ENV__) || SELECTED_ENV === "preprod") {
    console.log = function no_console() {
    };

}

const App = ({routes}) => {
    useFirstRenderHistoryLogic();
    useSentry();
    const {user, loggedin} = useSelector(({auth}) => auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const [userData, setUserData] = useState(parseJsonSafely(urlParams.get("user")));

    useEffect(() => {
        if (!loggedin) return;
        oldUserIdPropertyEvent(user.phone_hashed, "window_load");
    }, []);

    useHashToLocalStoreEvent();

    useEffect(() => {
        // get user data from url with token and set them into localsrotrage & cookies
        // try to parse user json in case it exists on query string & is valid json
        if (userData && userData.id && userData.token) {
            setAccessToken(userData.token);
            setUserId(userData.id);
            setUserToLocalStorage(userData);
            dispatch({type: "SET_USER_DATA", payload: userData});
        }
    }, [userData?.token, userData?.id]);
    const posthog = usePostHog();

    useEffect(() => {
        setUserData(null);
        const uuid = cookieClient.load("UUID") || null;
        if (loggedin) {
            posthog?.identify(user.id, {
                uuid: uuid
            })
        } else if (uuid) {
            posthog?.identify(uuid, {
                uuid: uuid
            });
        }
    }, [loggedin]);


    useEffect(() => {
        window.toast = toast;
        dispatch(
            unbxdAnalytics({
                url: serverUrl + location.pathname + location.search,
                action: "visitor",
            })
        );
    }, []);

    if (userData) {
        return null;
    }
    useSetReferral();

    return routes ? (
        <ScrollToTop>
            <ConfirmPopup />
            <Toaster
                position="bottom-center"
                toastOptions={{
                    style: {
                        // show on top of all elements
                        zIndex: 99999,
                        border: '1px solid #713200',
                        padding: '16px',
                        color: '#713200',

                    },
                }}/>
            <Routes>
                {routes.map((route, index) => {
                    // pass in the initialData from the server or window.DATA for this
                    // specific route
                    const Element = route.component;
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            element={<Element/>}
                        />
                    );
                })}
                <Route path="*" element={<Page404/>}/>
            </Routes>
        </ScrollToTop>
    ) : null;
};

export default App;
