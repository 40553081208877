import {serverUrl} from "@globalConfig";
import {Helmet} from "react-helmet";
import {trackEvents} from "@/common/events/events";

let appLanguage = "ar";
if (typeof window !== "undefined") {
    appLanguage = window.location.href.includes("/en") ? "en" : "ar";
}

export const objectToHash = (hash) => {
    let hashParams = [];
    for (const [key, value] of Object.entries(hash)) {
        hashParams.push(`${key}=${value}`);
    }
    return hashParams.length > 0 ? "#" + hashParams.join("&") : "";
}

//Add Title tag on the header for eachPage
export const setHeaderTags = (title, metaTags = [], headerCode = null) => {
    return (
        <Helmet>
            <title>{title}</title>
            {metaTags.length > 0 &&
                metaTags.map((tag, i) => {
                    return <meta key={i} name={tag.name} content={tag.content}/>;
                })}
            {headerCode && headerCode}
        </Helmet>
    );
};

//Create Link with params
export const createUrlWithParams = (path = null, params = {}) => {
    let urlParams = [];

    if (params !== null) {
        for (const [key, value] of Object.entries(params)) {
            urlParams.push(`${key}=${value}`);
        }
    }

    return `${serverUrl}${path ? path : ""}${
        urlParams.length > 0 ? "?" + urlParams.join("&") : ""
    }`;
};

//Create Internal Link with params
export const createInternalUrlWithParams = (
    path = null,
    params = {},
    lang = appLanguage,
    hash = {}
) => {
    let langParam = lang !== "ar" ? "/en" : "";
    let urlParams = [];
    for (const [key, value] of Object.entries(params)) {
        urlParams.push(`${key}=${value}`);
    }

    if(lang === 'en' && ['/','/en','/en/'].includes(path)){
       path='';
    }

    return `${langParam}${path ? path : ""}${
        urlParams.length > 0 ? "?" + urlParams.join("&") : ""
    }${objectToHash(hash)}`;
};

//Create SEO URL
export const createSeoUrlWithParams = (
    path = null,
    params = {},
    lang = undefined
) => {
    let langParam = appLanguage !== "ar" ? `/${appLanguage}` : "";
    if (lang) {
        langParam = lang !== "ar" ? "/en" : "";
    }
    let urlParams = [];
    if (params && Object.keys(params).length > 0) {
        for (const [key, value] of Object.entries(params)) {
            urlParams.push(`${key}=${value}`);
        }
    }
    const finalParams = urlParams.length > 0 ? "?" + urlParams.join("&") : "";
    return `${langParam}${path ? path : ""}${finalParams}`;
};

// Create Home SEO URL
export const createHomeSeoUrl = (isSwitch, pathName, lang = undefined) => {
    const switchedLang = lang === "en" ? "ar" : "en";
    const paths = [{ar: "/home/new-cars", en: "/en/home/new-cars"}];
    let url = "";
    if (isSwitch) {
        paths.filter((item) => {
            if (item[lang] === pathName) {
                url = item[switchedLang];
            }
        });
        return `${url ? url : ""}`;
    }
    return `${pathName}`;
};
//Create Years array
export const getYearsArray = (values) => {
    const years = [];
    for (let i = parseInt(values[0].name); i < parseInt(values[1].name); i++) {
        years.push(i);
    }
    years.push(parseInt(values[1].name));
    // added by sohain untel we change api values
    //years.push(parseInt(2023));

    return years.reverse();
};

//extract extentions from models
export const extractExtentions = (modelId, models) => {
    let extentions;
    if (modelId !== "" && models) {
        let model = models.values.find((object) => object.id == modelId);
        extentions = model ? model.extentions : undefined;
    }
    return extentions;
};


export const serverSwitchUrl = (serverUrl, req) => {
    let pathname = req;
    if (pathname.includes("/en")) {
        pathname = pathname.replace(/^\/en/, "");
    } else {
        pathname = `/en${pathname}`;
    }

    return cleanTrailingSlash(`${serverUrl}${pathname}`);
};

export const createSwitchUrl = (location) => {
    let pathname = location.pathname;
    if (pathname.includes("/en")) {
        pathname = pathname.replace(/^\/en/, "");
    } else {
        pathname = `/en${pathname}`;
    }
    return `${serverUrl}${cleanTrailingSlash(pathname)}${location.search}`;
};

export async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
        //secure origin (HTTPS)
        return await navigator.clipboard.writeText(text);
    } else {
        //NOT secure origin (HTTP)
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Unable to copy to clipboard", err);
        }
        document.body.removeChild(textArea);
    }
}

export function getNormalizePathName(url, lowerCase) {
    url = url.split("?")[0].replace("/en/", "/").trim();
    url = url
        .split("_")
        .filter(Boolean)
        .join("_")
        .replaceAll("_-", "-")
        .replaceAll("-_", "-")
        .substring(1);

    url = lowerCase ? url.toLowerCase() : url;
    return decodeURIComponent(url);
}

export function getLocalizedDate(date, language, isMonthInNumbers = false, implodeDateBy = "/") {
    const day = new Intl.DateTimeFormat(language, {weekday: "long"}).format(
        date
    );
    const monthinAlphabet = new Intl.DateTimeFormat(language, {
        month: "long",
    }).format(date);
    const year = date.getFullYear();
    const dayOfMonth = date.getDate();
    const monthInNumbers = date.getMonth() + 1; // Adjusting month to start from 1
    const formattedMonth = isMonthInNumbers
        ? monthInNumbers.toString().padStart(2, "0")
        : monthinAlphabet;

    return {day: day, date: `${dayOfMonth} ${implodeDateBy} ${formattedMonth} ${implodeDateBy} ${year}`};
}

export function getFormatTime(date, language = "ar") {
    // Extract hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine whether it's morning or evening
    const periodOfDay = hours < 12 ? "صباحا" : "مساءا";

    // Convert to 12-hour format
    const twelveHourFormat = hours >= 12 ? "PM" : "AM";

    // Format the hours
    const formattedHours = hours % 12 || 12; // Handle midnight (12 AM)

    return {
        time: `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes}`,
        format: `${language === "ar" ? periodOfDay : twelveHourFormat}`,
    };
}

export const handleHasAccountEvent = ({
                                          hasAccount,
                                          status,
                                          phone_number,
                                          lang,
                                          city_name = null,
                                          user_name = null,
                                          referral_location = "direct_account",
                                      }) => {
    if (hasAccount) {
        trackEvents("user_login", {
            user_login_status: status,
            user_id: `966${phone_number?.slice(1)}`,
            ui_language: lang.toUpperCase(),
            referral_location: referral_location,
        });
    } else {
        trackEvents("user_signup", {
            user_id: `966${phone_number?.slice(1)}`,
            ui_language: lang.toUpperCase(),
            user_signup_status: status,
            user_city: city_name,
            user_name: user_name,
            referral_location: referral_location,
        });
    }
};


export function cleanTrailingSlash(url) {
    return url.replace(/\/$/, "");
}

export function isNoIndexFollow(queryObj, isPagination) {
    const queryKeys = Object.keys(queryObj);
    return queryKeys.length > 0 && !(queryKeys.length === 1 && queryKeys[0] === 'page' && isPagination)
}


