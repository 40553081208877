import { UPDATE_USER_DATA, GET_USER_FAVORITE, LOGOUT_USER } from "../types";
import { LOGIN_RESOURCE } from "../../utils/constants";

const initialState = {
  userData: null,
  resetPassword: false,
  userLoginSource: null,
  prevPostId: null,
  userDataProfile: null,
  favoriteList: [],
  qualitySection: [],
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGOUT_USER:
      return {
        ...state,
        userData: initialState.userData,
      };
    case LOGIN_RESOURCE:
      return {
        ...state,
        userLoginSource: payload.source,
        prevPostId: payload.postId,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        userDataProfile: payload,
      };
    case GET_USER_FAVORITE:
      return {
        ...state,
        favoriteList: payload.posts,
        qualitySection: payload.quality_section
          ? payload.quality_section
          : state.qualitySection,
      };
    default:
      return state;
  }
};

export default userReducer;
