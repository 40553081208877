import { useContext, useEffect } from "react";
import cookieClient from "react-cookies";
import { useState } from "react";
import LocationContext from "@/common/Contexts/LocationContext";
var COOKIE_NANE = "webview_v2";
function getLocationParams(search) {
  return new URLSearchParams(search);
}
export default function useIsWebView() {
  if (__IS_SSR__) {
    var locationContext = useContext(LocationContext);
    var _params = getLocationParams(locationContext.search || "");
    console.log('params.get("webview")', _params.get("webview"));
    return !!_params.get("webview");
  }
  var params = getLocationParams(location.search);
  var _useState = useState(!!params.get("webview")),
    isWebView = _useState[0],
    setIsWebView = _useState[1];
  useEffect(function () {
    if (cookieClient.load(COOKIE_NANE)) {
      setIsWebView(true);
    } else if (isWebView) {
      cookieClient.save(COOKIE_NANE, "1", {
        path: "/",
        maxAge: 3600 * 24 * 365 * 10,
        // Cookie will expire after 10 years
        sameSite: true
      });
    } else if (isWebView) {
      console.warn('ignored webview due to user agent');
    }
  }, [isWebView]);
  return isWebView;
}