import { GET_POST_INSPECTION_DATA } from "../types";
const initialState = {
  inspectionData: {},
};

const inpectionViewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_POST_INSPECTION_DATA:
      return {
        ...state,
        inspectionData: payload,
        
      };

    default:
      return state;
  }
};
export default inpectionViewReducer;
