import {
  LOADING,
  ERRORS,
  REFRESHING,
  FOOTER_LOADING,
  SECONDARY_LOADING,
  SET_USER_DATA,
  POPUP_CAMPAIGN,
  CONTACT_US_SUBMIT,
  CONTACT_US_ERRORS,
  POST_LOADING,
  GET_CONTACT_US_DATA,
} from "../types";
const initialState = {
  loading: false,
  refreshing: false,
  footerLoading: false,
  secondaryLoading: false,
  errors: [],
  contactUs: {},
  campaginData: [],
  postLoading: false
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      if (payload)
        return {
          ...state,
          loading: payload,
          errors: [],
        };
      else return { ...state, loading: payload };
    case FOOTER_LOADING:
      return {
        ...state,
        footerLoading: payload,
      };
    case SECONDARY_LOADING:
      return {
        ...state,
        secondaryLoading: payload,
      };
    case POST_LOADING:
      return {
        ...state,
        postLoading: payload,
      };
    case REFRESHING:
      return { ...state, refreshing: payload };
    case SET_USER_DATA:
      if (!payload.contact_us) return { ...state };
      return { ...state, contactUs: payload.contact_us };
    case ERRORS:
      return { ...state, errors: payload };
    case POPUP_CAMPAIGN:
      return {
        ...state,
        campaginData: payload.data,
        contactUs: payload.data.contact_us,
      };
    case CONTACT_US_SUBMIT:
      return { ...state, ContactUsSubmit: payload.data };
    case CONTACT_US_ERRORS:
      return { ...state, errors: payload.data };
    case GET_CONTACT_US_DATA:
      return { ...state, contactUs: payload };
    default:
      return state;
  }
};
export default commonReducer;
