import {
  GET_SEARCH_FILTERS,
  SET_MODELS_EXTENTIONS,
  GET_SEARCH_SUGGESTIONS,
  GET_INITIAL_SEARCH_SUGGESTIONS,
  UPDATE_SEARCH_POSTS,
  UPDATE_SIMILAR_ITEMS,
} from "../types";
const initialState = {
  newSearchParams: {},
  searchParams: {
    is_online: "",
    car_shape: "",
    deal: "",
    deal_id: "",
    make_id: "",
    model_id: "",
    extension_id: "",
    is_new: "",
    year_from: "",
    year_to: "",
    city_id: "",
    car_options: "",
    price_from: "",
    price_to: "",
    car_origin: "",
    fuel_type: "",
    engine_size: "",
    transmission_type: "",
    drivetrain_type: "",
    color: "",
    interior_color: "",
    cylindars: "",
    cabin_numbers: "",
    post_type: "",
    post_type_payment: "",
    payment_type: "",
    milage_from: "",
    milage_to: "",
    milage_range: "",
    monthly_installment_from: "",
    monthly_installment_to: "",
    keyword: "",
    sort: "",
    owned_by_us: "",
    reserved_status: "",
    options_list: "",
    has_discount: 0,
    hero_area_id: "",
  },
  filters: {},
  models: null,
  params: null,
  count: "",
  posts: [],
  stories: [],
  deal: [],
  searchTitle: "",
  similarItems: [],
  totalNumberOfPages: 10,
  suggestions: [],
  initalSuggestions: [],
  metaTags: [],
  heros: [],
  url: "",
  qualitySection: [],
  usps: [],
  marketingBanner: {},
  filter_tags: [],
  offersList: {}
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SEARCH_FILTERS:
      return {
        ...state,
        filters: payload,
      };


    case SET_MODELS_EXTENTIONS:
      return {
        ...state,
        models: payload.models,
      };


    case GET_SEARCH_SUGGESTIONS:
      return {
        ...state,
        suggestions: payload,
      };

    case GET_INITIAL_SEARCH_SUGGESTIONS:
      return {
        ...state,
        initalSuggestions: payload,
      };

    case UPDATE_SEARCH_POSTS:
      const updatedPost = state.posts.find((post) => post.id === payload);
      const updatedPostIndex = state.posts.findIndex((post) => {
        return post.id === payload;
      });

      const updatedPosts = [...state.posts];
      updatedPosts[updatedPostIndex] = {
        ...updatedPost,
        isFavorite: !updatedPost?.isFavorite,
      };

      return {
        ...state,
        posts: updatedPosts,
      };
    case UPDATE_SIMILAR_ITEMS:
      const item = state.similarItems.find((item) => item.id === payload);
      const itemIndex = state.similarItems.findIndex((item) => {
        return item.id === payload;
      });

      const updatedItems = [...state.similarItems];
      updatedItems[itemIndex] = {
        ...item,
        isFavorite: !item.isFavorite,
      };

      return {
        ...state,
        similarItems: updatedItems,
      };

    default:
      return state;
  }
};
export default searchReducer;
