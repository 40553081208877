import {
  GET_HOME_PAGE_DATA,
  GET_MARKETING_PAGE_DATA,
  GET_HOME_PAGE_DATA_NEW,
  UPDATE_HOME_TAB,
  GET_HOME_PAGE_NEW,
  GET_HOME_PAGE_USED,
} from "../types";
const initialState = {
  selectedTab: 0,
  homeData: {
    car_shapes: [],
    makes: [],
    post_online_count: "",
    section: [],
    faqs: [],
    userFeedback: {},
    testimonials: [],
    contactUs: {},
    notification: false,
    mileage_ranges: [],
    price_ranges: [],
  },
  homeDataNew: {
    car_shapes: [],
    makes: [],
    post_online_count: "",
    section: [],
    faqs: [],
    userFeedback: {},
    testimonials: [],
    contactUs: {},
    notification: false,
    mileage_ranges: [],
    price_ranges: [],
    homepage_sliders: [],
  },
  homeNew: {
    car_shapes: [],
    makes: [],
    post_online_count: "",
    section: [],
    faqs: [],
    userFeedback: {},
    testimonials: [],
    contactUs: {},
    notification: false,
    mileage_ranges: [],
    price_ranges: [],
  },
  homeUsed: {
    car_shapes: [],
    makes: [],
    post_online_count: "",
    section: [],
    faqs: [],
    userFeedback: {},
    testimonials: [],
    contactUs: {},
    notification: false,
    mileage_ranges: [],
    price_ranges: [],
  },
  marketingData: {
    posts: [],
    title: "",
    description: "",
    contentMessage: "",
    headerMessage: "",
    mainImageMobile: "",
    mainImageDesktop: "",
    backgroundType: "",
    ordersCount: "",
    searchFilters: "",
    showSearch: false,
    mileage_ranges: [],
    price_ranges: [],
  },
};

const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_HOME_PAGE_DATA:
      return {
        ...state,
        homeData: {
          car_shapes: payload.car_shapes.values,
          makes: payload.makes.values,
          post_online_count: payload.post_online_count,
          section: payload.section,
          faqs: payload.faq,
          userFeedback: payload.users_feedback,
          testimonials: payload.testimonial_videos,
          contactUs: payload.contact_us,
          notification: payload.notification,
          heros: payload.heros_area,
          mileage_ranges: payload.milage_ranges,
          price_ranges: payload.price_ranges,
          odometer_ranges: payload.odometer_ranges,
        },
      };
    case GET_HOME_PAGE_DATA_NEW:
      return {
        ...state,
        homeDataNew: {
          car_shapes: payload.car_shapes.values,
          makes: payload.makes.values,
          post_online_count: payload.post_online_count,
          section: payload.section,
          faqs: payload.faq,
          userFeedback: payload.users_feedback,
          testimonials: payload.testimonial_videos,
          contactUs: payload.contact_us,
          notification: payload.notification,
          suggestion: payload.mobile_posts || payload.default_post,
          deals: payload.deal_offers,
          heros: payload.heros_area,
          mileage_ranges: payload.milage_ranges,
          price_ranges: payload.price_ranges,
          homepage_sliders: payload.homepage_sliders,
          odometer_ranges: payload.odometer_ranges,
        },
      };
    case GET_HOME_PAGE_NEW:
      return {
        ...state,
        homeNew: {
          car_shapes: payload.car_shapes.values,
          makes: payload.makes.values,
          post_online_count: payload.post_online_count,
          section: payload.section,
          faqs: payload.faq,
          userFeedback: payload.users_feedback,
          testimonials: payload.testimonial_videos,
          contactUs: payload.contact_us,
          notification: payload.notification,
          deals: payload.deal_offers,
          heros: payload.heros_area,
          mileage_ranges: payload.milage_ranges,
          price_ranges: payload.price_ranges,
          odometer_ranges: payload.odometer_ranges,
        },
      };
    case GET_HOME_PAGE_USED:
      return {
        ...state,
        homeUsed: {
          car_shapes: payload.car_shapes.values,
          makes: payload.makes.values,
          post_online_count: payload.post_online_count,
          section: payload.section,
          faqs: payload.faq,
          userFeedback: payload.users_feedback,
          testimonials: payload.testimonial_videos,
          contactUs: payload.contact_us,
          notification: payload.notification,
          deals: payload.deal_offers,
          heros: payload.heros_area,
          mileage_ranges: payload.milage_ranges,
          price_ranges: payload.price_ranges,
          odometer_ranges: payload.odometer_ranges,
        },
      };
    case GET_MARKETING_PAGE_DATA:
      return {
        ...state,
        marketingData: {
          posts: payload.posts,
          title: payload.title,
          description: payload.description,
          contentMessage: payload.content_message,
          headerMessage: payload.header_message,
          mainImageMobile: payload.main_image_mobile,
          mainImageDesktop: payload.main_image_desktop,
          backgroundType: payload.background_type,
          ordersCount: payload.orders_count,
          searchFilters: payload.search,
          showSearch: payload.show_search,
          video: payload.video_path,
          heros: payload.heros_area,
          mileage_ranges: payload.milage_ranges,
          price_ranges: payload.price_ranges,
          odometer_ranges: payload.odometer_ranges,
        },
      };
    case UPDATE_HOME_TAB:
      return {
        ...state,
        selectedTab: payload,
      };
    default:
      return state;
  }
};
export default homeReducer;
