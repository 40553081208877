import { GET_FEEDBACK_LIST, LOADING } from "../types";

const initialState = {
  feedbackList: [],
  loading: false,
  totalPages: 0,
};

const feedbackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case GET_FEEDBACK_LIST:
      return {
        ...state,
        // ...state,
        totalPages: payload.pagination.total_pages,
        totalFeedbacks: payload,
        feedbackList:
          payload.pagination.current_page == 1
            ? payload.feedback_list
            : [...state.feedbackList, ...payload.feedback_list],
      };
    default:
      return state;
  }
};

export default feedbackReducer;
