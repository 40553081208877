import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = ({children}) => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = "";
    }, [location]);

    return children;
};

export default ScrollToTop;
