// useWebView.ts
import { useCallback } from 'react';
import useIsIOS from "@hooks/useIsIOS";
// Define the interface for WebAppInterface
// Extend the Window interface to include WebAppInterface
/**
 * Custom hook to interact with the Android WebAppInterface with Promise-based functions.
 *
 * @returns An object containing methods to interact with WebAppInterface.
 */
var _useAndroidWebview = function _useAndroidWebview() {
  /**
   * A reusable function to call a WebAppInterface method.
   *
   * @param methodName - The name of the WebAppInterface method to call.
   * @param args - Arguments to pass to the method.
   * @returns {Promise<void>}
   */
  var callWebAppInterface = useCallback(function (methodName) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return new Promise(function (resolve, reject) {
      if (typeof window !== 'undefined' && window.Android && typeof window.Android[methodName] === 'function') {
        try {
          var _window$Android;
          (_window$Android = window.Android)[methodName].apply(_window$Android, args);
          resolve();
        } catch (error) {
          console.error("Error executing ".concat(methodName, ":"), error);
          reject(error);
        }
      } else {
        var error = new Error("Android.".concat(methodName, " is not available."));
        console.warn(error.message);
        reject(error);
      }
    });
  }, []);
  /**
   * Opens a file from a Base64 string.
   *
   * @param base64String - The Base64 encoded string of the file.
   * @param originalFileName - The original name of the file.
   * @param mimeType - The MIME type of the file.
   * @returns {Promise<void>}
   */
  var openBase64File = useCallback(function (base64String, originalFileName, mimeType) {
    return callWebAppInterface('openBase64File', base64String, originalFileName, mimeType);
  }, [callWebAppInterface]);
  /**
   * Opens a file from a URL.
   *
   * @param fileUrl - The URL of the file to open.
   * @returns {Promise<void>}
   */
  var openFile = useCallback(function (fileUrl) {
    return callWebAppInterface('openFile', fileUrl);
  }, [callWebAppInterface]);
  /**
   * Shows a toast message.
   *
   * @param message - The message to display in the toast.
   * @returns {Promise<void>}
   */
  var showToast = useCallback(function (message) {
    return callWebAppInterface('showToast', message);
  }, [callWebAppInterface]);
  /**
   * Closes the current screen.
   *
   * @returns {Promise<void>}
   */
  var closeScreen = useCallback(function () {
    return callWebAppInterface('closeScreen');
  }, [callWebAppInterface]);
  /**
   * Shows or hides the title bar.
   * @param isVisible - A boolean value to show or hide the title bar.
   * @returns {Promise<void>}
   */
  var showTitleBar = useCallback(function (isVisible) {
    return callWebAppInterface('showTitleBar', isVisible);
  }, [callWebAppInterface]);
  /**
   * OpenUri screen.
   * @returns {Promise<void>}
   */
  var openUri = useCallback(function (uri) {
    return callWebAppInterface('openUri', uri);
  }, [callWebAppInterface]);
  /**
   * OpenUri screen.
   * @returns {Promise<void>}
   */
  var logger = useCallback(function (tag, message) {
    return callWebAppInterface('logger', tag, message);
  }, [callWebAppInterface]);
  return {
    openBase64File: openBase64File,
    openFile: openFile,
    showToast: showToast,
    closeScreen: closeScreen,
    showTitleBar: showTitleBar,
    openUri: openUri,
    logger: logger
  };
};
/**
 * Custom hook to interact with the iOS WebAppInterface with Promise-based functions.
 *
 * @returns An object containing methods to interact with WebAppInterface.
 */
var _useIosWebview = function _useIosWebview() {
  /**
   * A reusable function to call an iOS WebAppInterface method.
   *
   * @param handlerName - The name of the message handler to call.
   * @param data - Data to pass to the handler.
   * @returns {Promise<void>}
   */
  var callIosWebAppInterface = useCallback(function (handlerName, data) {
    return new Promise(function (resolve, reject) {
      if (typeof window !== 'undefined' && window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[handlerName]) {
        try {
          window.webkit.messageHandlers[handlerName].postMessage(data);
          resolve();
        } catch (error) {
          console.error("Error executing ".concat(handlerName, ":"), error);
          reject(error);
        }
      } else {
        var error = new Error("iOS handler ".concat(handlerName, " is not available."));
        console.warn(error.message);
        reject(error);
      }
    });
  }, []);
  var openBase64File = useCallback(function (base64String, originalFileName, mimeType) {
    return callIosWebAppInterface('openBase64File', {
      base64String: base64String,
      originalFileName: originalFileName,
      mimeType: mimeType
    });
  }, [callIosWebAppInterface]);
  var openFile = useCallback(function (fileUrl) {
    return callIosWebAppInterface('openFile', fileUrl);
  }, [callIosWebAppInterface]);
  var showToast = useCallback(function (message) {
    return callIosWebAppInterface('showToast', message);
  }, [callIosWebAppInterface]);
  var closeScreen = useCallback(function () {
    return callIosWebAppInterface('closeScreen', {});
  }, [callIosWebAppInterface]);
  var showTitleBar = useCallback(function (isVisible) {
    return callIosWebAppInterface('showTitleBar', {
      isVisible: isVisible
    });
  }, [callIosWebAppInterface]);
  var openUri = useCallback(function (uri) {
    return callIosWebAppInterface('openUri', {
      uri: uri
    });
  }, [callIosWebAppInterface]);
  var logger = useCallback(function (tag, message) {
    return callIosWebAppInterface('logger', {
      tag: tag,
      message: message
    });
  }, [callIosWebAppInterface]);
  return {
    openBase64File: openBase64File,
    openFile: openFile,
    showToast: showToast,
    closeScreen: closeScreen,
    showTitleBar: showTitleBar,
    openUri: openUri,
    logger: logger
  };
};
var useWebView = function useWebView() {
  var is_ios = useIsIOS();
  if (is_ios) {
    return _useIosWebview();
  }
  return _useAndroidWebview();
};
export default useWebView;