import {
  GET_NEW_SEARCH_RESULTS,
  SEARCH_LOADING,
  SET_PRE_SELECTED_FILTERS,
  UPDATE_SEARCH_POSTS,
} from "../types";

const initialState = {
  posts: [],
  stories: [],
  deal: [],
  searchTitle: "",
  similarItems: [],
  metaTags: [],
  heros: [],
  url: "",
  totalPages: 0,
  qualitySection: [],
  usps: [],
  marketingBanner: {},
  postsTitle: "",
  preSelectedFilters: [],
  filter_tags: [],
  count: null,
  preFilters: {},
  loading: {
    postsLoading: false,
    tagsLoading: false,
    filtersLoading: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NEW_SEARCH_RESULTS:
      return {
        ...state,
        posts:
          payload.page == 1
            ? payload.items
            : [...state.posts, ...payload.items],
        deal: payload.deal,
        stories: payload.USPStories,
        searchTitle: payload.title,
        similarItems: payload.similar_items,
        metaTags: payload.meta_tags,
        postsTitle: payload.postsTitle,
        url: payload.url,
        heros: payload.heros,
        qualitySection: payload.qualitySection,
        usps: payload.usps,
        marketingBanner: payload.marketingBanner,
        totalPages: payload.pagination.total_pages,
        filter_tags: payload.filter_tags,
        count: payload.count,
      };
    case SET_PRE_SELECTED_FILTERS:
      return {
        ...state,
        preSelectedFilters: payload,
      };
    case SEARCH_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...payload },
      };
    case UPDATE_SEARCH_POSTS:
      const updatedPost = state.posts.find((post) => post.id === payload);
      const updatedPostIndex = state.posts.findIndex((post) => {
        return post.id === payload;
      });

      const updatedPosts = [...state.posts];
      updatedPosts[updatedPostIndex] = {
        ...updatedPost,
        isFavorite: !updatedPost?.isFavorite,
      };

      return {
        ...state,
        posts: updatedPosts,
      };

    default:
      return state;
  }
};
