import {
  GET_TRENDS_LIST,
  GET_TRENDS_PAGINATION,
  GET_SINGLE_TREND,
} from "../types";

const initialState = {
  trendsList: [],
  singleTrend: {},
  TrendsPagination: {
    current_page: 0,
    total_pages: 0,
  },
};

const trendsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRENDS_LIST:
      return {
        ...state,
        trendsList: payload,
      };
    case GET_TRENDS_PAGINATION:
      return {
        ...state,
        TrendsPagination: payload,
      };
    case GET_SINGLE_TREND:
      return {
        ...state,
        singleTrend: payload,
      };

    default:
      return state;
  }
};

export default trendsReducer;
